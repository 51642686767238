$document = $(document);
$document.ready(function() {
  $window = $(window);
  $body = $("body");
  $header = $("#banner");
  $footer = $("#footer");
  $navToggle = $("#nav-open button, #nav-close button");
  $navClose = $("#nav-close");
  $menu = $("#menu");

  let atTop = true;
  let atTopLast = true;
  $document.on("scroll", function() {
    if ($document.scrollTop() === 0) {
      atTop = true;
    } else {
      atTop = false;
    }
    if (atTop !== atTopLast) {
      $header.addClass(atTop ? "bg-dark" : "bg-light");
      $header.removeClass(!atTop ? "bg-dark" : "bg-light");
      atTopLast = atTop;
    }
  });

  // set body padding for header size. Then again on resize.
  setBodyTop();
  $window.on("resize", function() {
    setBodyTop();
  });
  setBodyBottom();
  $window.on("resize", function() {
    setBodyBottom();
  });

  $navToggle.on("click", function() {
    // toggle menu control
    $menu.toggleClass("open");
  });

  $(".target-smart-table, .entry-content table").each(function() {
    let $table = $(this);
    // let $th = $table.find("th");
    let $td = $table.find("td");
    let $tr = $table.find("tr");
    $table.find("th").each(function(i) {
      let $th = $(this);
      $tr.each(function(a) {
        $(this)
          .children()
          .eq(i)
          .attr("data-label", $th.text());
      });
    });
  });

  $(".target-smart-share-button").each(function() {
    let $socials = $("<div class='target-smart-share-socials'></div>");
    let $button = $(this);
    $button.wrap("<div class='target-smart-share-container'></div>");
    let $container = $button.parent();
    $button.after($socials);
    let labels = $button.data("labels") ? $button.data("labels") : false;
    $button.on("click", function(e) {
      e.stopPropagation();
      let url = $button.data("url") || window.location.href;
      $container.addClass("open");
      $socials.jsSocials({
        showLabel: labels,
        showCount: false,
        shares: [
          {
            renderer: function() {
              let labelText = labels
                ? '<span class="jssocials-share-label">Copy URL</span>'
                : "";
              let $copy = $(
                '<div class="copy"><a href="javascript:;"><i class="fas fa-copy jssocials-share-logo"></i>' +
                  labelText +
                  '</a><div class="message"><div>URL Copied!</div></div></div>'
              );
              $copy.on("click", function(e) {
                e.stopPropagation();
                copyTextToClipboard(url, $copy[0], function() {
                  $copy.addClass("copied");
                  setTimeout(function() {
                    $copy.removeClass("copied");
                  }, 1000);
                });
              });
              return $copy;
            }
          },
          {
            label: "Twitter",
            share: "twitter",
            logo: "fab fa-twitter",
            url: url
          },
          {
            label: "Facebook",
            share: "facebook",
            logo: "fab fa-facebook",
            url: url
          },
          {
            label: "Email",
            share: "email",
            logo: "fas fa-envelope",
            url: url
          }
        ]
      });
      $(window).one("click", function() {
        $container.removeClass("open");
        $socials.html("");
      });
    });
  });

  loadSiteConfig()
    .then(function(config) {
      if (config.alerts) setAlerts(config.alerts);
      if (config.banner) setBanner(config.banner);
    })
    .catch(function(err) {
      console.log(err);
    });
});

function setBanner(banner) {
  let $banner = $(
    '<div class="alert rounded-0 m-0 text-center ' +
      (banner.class ? banner.class : "alert-primary") +
      '" role="alert">' +
      banner.message +
      "</div>"
  );
  $header.append($banner);
  setBodyTop();
}

function setAlerts(alerts) {
  Object.keys(alerts).forEach(function(name) {
    let alert = alerts[name];
    if (alert.once && alert.once === true) {
      if (!localStorage.getItem(name)) {
        showAlert(alert, name);
      }
    } else {
      showAlert(alert);
    }
  });
}

function showAlert(alert, name) {
  let $modal = modalTemplate(alert.title, alert.message, alert.actions);
  $body.append($modal);
  $modal.modal();
  $modal.modal("show");
  if (alert.once && alert.once === true) {
    $modal.on("hidden.bs.modal", function(e) {
      localStorage.setItem(name, true);
    });
  }
}

function loadSiteConfig() {
  return new Promise(function(resolve, reject) {
    $.get("/static/config/config.json")
      .done(resolve)
      .fail(reject);
  });
}

function setBodyTop() {
  let headerHeight =
    $header.height() +
    parseInt($header.css("padding-top").replace("px", "")) +
    parseInt($header.css("padding-bottom").replace("px", ""));
  $body.css("padding-top", headerHeight);
  $navClose.css("height", headerHeight);
}

function setBodyBottom() {
  let footerHeight =
    $footer.height() +
    parseInt($footer.css("padding-top").replace("px", "")) +
    parseInt($footer.css("padding-bottom").replace("px", ""));
  $body.css("padding-bottom", footerHeight);
}

function fallbackCopyTextToClipboard(text, el, success) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.contentEditable = true;
  textArea.readOnly = false;
  range = document.createRange();
  el.appendChild(textArea);
  textArea.focus();
  textArea.select();

  var s = window.getSelection();
  s.removeAllRanges();
  s.addRange(range);

  textArea.setSelectionRange(0, 999999);

  try {
    var successful = document.execCommand("copy");
    if (!successful) {
      alert("failed to copy");
    } else {
      success();
    }
  } catch (err) {
    alert("failed to copy");
  }

  el.removeChild(textArea);
}

function copyTextToClipboard(text, el, success) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, el, success);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function() {
      success();
    },
    function(err) {
      alert("failed to copy");
    }
  );
}

function modalTemplate(title, body, actions) {
  return $(
    '<div class="modal fade show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">' +
      '<div class="modal-dialog" role="document">' +
      '<div class="modal-content">' +
      '<div class="modal-header">' +
      '<h5 class="modal-title">' +
      title +
      "</h5>" +
      '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
      '<span class="fas fa-times" aria-hidden="true"></span>' +
      "</button>" +
      "</div>" +
      '<div class="modal-body">' +
      body +
      "</div>" +
      '<div class="modal-footer">' +
      (actions
        ? actions
        : '<button type="button" class="btn btn-primary" data-dismiss="modal">Got it, thanks!</button>') +
      "</div>" +
      "</div>" +
      "</div>" +
      "</div>"
  );
}
